import type { ComponentProps } from 'react';
import { Field } from 'react-final-form';

import { type TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { Input } from '@hh.ru/magritte-ui';

import { AuthCredentialType } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/utils';
import { required } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import type { LoginFormValues } from 'src/components/AccountLogin/types';
import { useAutofocusRenderOnce } from 'src/hooks/useAutofocus';

const TrlKeys = {
    valueMissing: 'form.error.valueMissing',
};

interface CredentialFieldProps {
    credentialType: AuthCredentialType;
}

const CredentialField: TranslatedComponent<CredentialFieldProps> = ({ trls, credentialType }) => {
    const getAutofocusRef = useAutofocusRenderOnce();

    return (
        <Field<LoginFormValues['username']>
            name="username"
            type="text"
            validate={required}
            render={({ input, meta }) => {
                const props: ComponentProps<typeof Input> = {
                    ...input,
                    size: 'large',
                    invalid: meta.invalid && meta.submitFailed,
                    errorMessage: meta.error ? trls[TrlKeys[meta.error as keyof typeof TrlKeys]] : null,
                    autoCapitalize: 'off',
                    autoCorrect: 'off',
                    spellCheck: 'false',
                    ...getAutofocusRef(input.value),
                };

                return (
                    <>
                        {credentialType === AuthCredentialType.Phone && <Input {...props} inputMode="tel" />}
                        {credentialType === AuthCredentialType.Email && <Input {...props} inputMode="email" />}
                    </>
                );
            }}
        />
    );
};

export default translation(CredentialField);
