import { useRef, type ComponentType, type FC } from 'react';
import { Form as FinalForm } from 'react-final-form';

import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';
import type { ApplicantLoginStep } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/types';
import Form from 'src/components/Form';
import mutators from 'src/utils/forms/mutators';

import AccountTypeSelectionStep from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/AccountTypeSelectionStep';
import EnterCredentialsStep from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep';
import SocialLoginStep from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/SocialLoginStep';
import { initialValues } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';

const EmptyComponent: FC = () => <></>;

const StepMap: Record<ApplicantLoginStep, ComponentType> = {
    'account-type-selection': AccountTypeSelectionStep,
    'enter-credentials': EnterCredentialsStep,
    'social-login': SocialLoginStep,
    empty: EmptyComponent,
};

const MultiStepForm: FC = () => {
    const { step, initialStep } = useApplicantLoginContext();
    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = (): void => {
        if (formRef.current) {
            // Collect data + Submit form
        }
    };

    const ActiveStep = StepMap[step ?? initialStep];

    return (
        <FinalForm
            initialValues={initialValues}
            mutators={mutators}
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <ActiveStep />
                </Form>
            )}
        />
    );
};

export default MultiStepForm;
