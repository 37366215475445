import { createContext, useCallback, useContext, useMemo, useState, type FC, type PropsWithChildren } from 'react';

import type {
    ApplicantLoginFlow,
    ApplicantLoginStep,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/types';

interface ApplicantLoginContextProps {
    flow: ApplicantLoginFlow | null;
    step: ApplicantLoginStep | null;
    initialStep: ApplicantLoginStep;
    onNextStep: (value: ApplicantLoginStep) => void;
    onPrevStep: VoidFunction;
    onFlowChange: (value: ApplicantLoginFlow | null) => void;
}

const ApplicantLoginContext = createContext<ApplicantLoginContextProps | null>(null);

interface ApplicantLoginProviderProps {
    initialFlow?: ApplicantLoginFlow | null;
    initialStep?: ApplicantLoginStep;
}

const ApplicantLoginProvider: FC<PropsWithChildren<ApplicantLoginProviderProps>> = ({
    children,
    initialFlow = null,
    initialStep = 'account-type-selection',
}) => {
    const [flow, setFlow] = useState<ApplicantLoginContextProps['flow']>(initialFlow);
    const [stepHistory, setStepHistory] = useState<ApplicantLoginStep[]>([initialStep]);

    const step: ApplicantLoginContextProps['step'] = useMemo(() => {
        const lastStepIndex = stepHistory.length - 1;
        return lastStepIndex >= 0 ? stepHistory[lastStepIndex] : null;
    }, [stepHistory]);

    const onNextStep: ApplicantLoginContextProps['onNextStep'] = useCallback((value) => {
        setStepHistory((prev) => [...prev, value]);
    }, []);

    const onPrevStep: ApplicantLoginContextProps['onPrevStep'] = useCallback(() => {
        setStepHistory((prev) => (prev.length ? prev.slice(0, -1) : prev));
    }, []);

    const onFlowChange: ApplicantLoginContextProps['onFlowChange'] = useCallback((value) => {
        setFlow(value);
    }, []);

    const value: ApplicantLoginContextProps = useMemo(
        () => ({
            flow,
            step,
            initialStep,
            onNextStep,
            onPrevStep,
            onFlowChange,
        }),
        [flow, step, initialStep, onNextStep, onPrevStep, onFlowChange]
    );

    return <ApplicantLoginContext.Provider value={value}>{children}</ApplicantLoginContext.Provider>;
};

const useApplicantLoginContext = (): ApplicantLoginContextProps => {
    const context = useContext<ApplicantLoginContextProps | null>(ApplicantLoginContext);

    if (!context) {
        throw new Error('Calling useApplicantLoginContext outside of ApplicantLoginProvider');
    }

    return context;
};

export { useApplicantLoginContext, ApplicantLoginContext };
export default ApplicantLoginProvider;
