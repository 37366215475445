import { useState } from 'react';
import { useForm } from 'react-final-form';

import type { TranslatedComponent } from '@hh.ru/front-static-app';
import { translation, useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer, Button, Title } from '@hh.ru/magritte-ui';

import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';
import { LoginFormValues } from 'src/components/AccountLogin/types';
import { useDisableSocialNetworkAuthExp } from 'src/hooks/useDisableSocialNetworkAuthExp';
import useIsIframeView from 'src/hooks/useIsIframeView';

import CredentialField from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/CredentialField';
import CredentialTypeSwitch from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/CredentialTypeSwitch';
import LegalInfo from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/LegalInfo';
import SocialLoginButton from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/SocialLoginButton';
import {
    AuthCredentialType,
    FlowKey,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/utils';

const TrlKeys = {
    [FlowKey.SignIn]: {
        title: 'applicant.login.step.enterCredentials.title.signIn',
        action: {
            next: 'applicant.login.step.enterCredentials.action.next',
            withPassword: 'applicant.login.step.enterCredentials.action.authWithPassword',
        },
    },
    [FlowKey.SignUp]: {
        title: 'applicant.login.step.enterCredentials.title.signUp',
        action: {
            next: 'applicant.login.step.enterCredentials.action.next',
        },
    },
};

const EnterCredentialsStep: TranslatedComponent = ({ trls }) => {
    const [credentialType, setCredentialType] = useState(AuthCredentialType.Phone);
    const { socialNetworks } = useSelector(({ loginForm }) => loginForm);
    const { flow, onNextStep } = useApplicantLoginContext();
    const { submit, getState } = useForm<LoginFormValues>();
    const isIframeView = useIsIframeView();
    const isDisableSocialNetworkAuthExp = useDisableSocialNetworkAuthExp();

    const flowKey = flow === 'sign-in' ? FlowKey.SignIn : FlowKey.SignUp;
    const shouldShowSocialNetworks = !!socialNetworks?.length && !isIframeView && !isDisableSocialNetworkAuthExp;

    const handleCredentialTypeChange = (value: AuthCredentialType) => {
        setCredentialType(value);
    };

    const handleNextButtonClick = async (): Promise<void> => {
        await submit();
        const isValid = getState().valid;

        if (isValid) {
            // Set OTP Step
            onNextStep('empty');
        }
    };

    const handleLoginWithPasswordButtonClick = async (): Promise<void> => {
        await submit();
        const isValid = getState().valid;

        if (isValid) {
            // Set Password Step
            onNextStep('empty');
        }
    };

    const handleSocialLoginButtonClick = (): void => {
        onNextStep('social-login');
    };

    return (
        <VSpacingContainer default={24}>
            <Title Element="h2" size="medium" alignment="center">
                {trls[TrlKeys[flowKey].title]}
            </Title>
            <CredentialTypeSwitch value={credentialType} onChange={handleCredentialTypeChange} />
            <CredentialField credentialType={credentialType} />
            <VSpacingContainer default={12}>
                <Button mode="primary" size="large" style="accent" onClick={handleNextButtonClick}>
                    {trls[TrlKeys[flowKey].action.next]}
                </Button>
                {flow === 'sign-in' && (
                    <Button mode="secondary" size="large" style="accent" onClick={handleLoginWithPasswordButtonClick}>
                        {trls[TrlKeys[FlowKey.SignIn].action.withPassword]}
                    </Button>
                )}
            </VSpacingContainer>
            {shouldShowSocialNetworks && (
                <SocialLoginButton socialNetworks={socialNetworks} onClick={handleSocialLoginButtonClick} />
            )}
            <LegalInfo />
        </VSpacingContainer>
    );
};

export default translation(EnterCredentialsStep);
