import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { BrandedButton } from '@hh.ru/magritte-ui';

import SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE from 'src/components/SignInSignUpFlow/types';
import type { SocialNetworks } from 'src/models/LoginForm';

const TrlKeys = {
    label: 'applicant.login.step.enterCredentials.action.authWithSocial',
};

interface SocialLoginButtonProps {
    socialNetworks: SocialNetworks[];
    onClick?: VoidFunction;
}

const SocialLoginButton: TranslatedComponent<SocialLoginButtonProps> = ({ trls, socialNetworks, onClick }) => {
    const services = socialNetworks
        .filter(({ code }) => !!SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE[code])
        .map(({ code }) => SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE[code]);

    return (
        <BrandedButton size="large" mode="secondary" service={services} onClick={onClick}>
            {trls[TrlKeys.label]}
        </BrandedButton>
    );
};

export default translation(SocialLoginButton);
