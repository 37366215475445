import type { ChangeEventHandler } from 'react';

import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { Segmented, Segment } from '@hh.ru/magritte-ui';

import { AuthCredentialType } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/utils';

const isCredentialType = (value: string): value is AuthCredentialType => {
    return Object.values(AuthCredentialType).includes(value as AuthCredentialType);
};

const TrlKeys = {
    [AuthCredentialType.Phone]: 'applicant.login.step.enterCredentials.credentialType.phone',
    [AuthCredentialType.Email]: 'applicant.login.step.enterCredentials.credentialType.email',
};

interface CredentialTypeSwitchProps {
    value: AuthCredentialType;
    onChange: (value: AuthCredentialType) => void;
}

const CredentialTypeSwitch: TranslatedComponent<CredentialTypeSwitchProps> = ({ trls, value, onChange }) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const { value } = event.target;

        if (isCredentialType(value)) {
            onChange(value);
        }
    };

    return (
        <Segmented selectedValue={value} mode="label" size="large" stretched onChange={handleChange}>
            <Segment value={AuthCredentialType.Phone}>{trls[TrlKeys[AuthCredentialType.Phone]]}</Segment>
            <Segment value={AuthCredentialType.Email}>{trls[TrlKeys[AuthCredentialType.Email]]}</Segment>
        </Segmented>
    );
};

export default translation(CredentialTypeSwitch);
