import { type FC } from 'react';

import { Card, useBreakpoint, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';

import { useApplicantRenewedAuthExp } from 'src/hooks/useApplicantRenewedAuthExp';

import EmployerLoginLink from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/EmployerLoginLink';
import Header from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/Header';
import MultiStepForm from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm';
import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';

const ExpApplicantLoginCard: FC = () => {
    const { isApplicantRenewedAuthExpC, isApplicantRenewedAuthExpD } = useApplicantRenewedAuthExp();
    const { isGtXS } = useBreakpoint();
    const { step } = useApplicantLoginContext();

    return (
        <VSpacingContainer default={12}>
            <Card showBorder={isGtXS} stretched padding={24} borderRadius={40}>
                {!isApplicantRenewedAuthExpD && <Header />}
                <VSpacing default={isApplicantRenewedAuthExpD ? 12 : 24} />
                <MultiStepForm />
            </Card>
            {isApplicantRenewedAuthExpC && step === 'enter-credentials' && <EmployerLoginLink />}
        </VSpacingContainer>
    );
};

export default ExpApplicantLoginCard;
